.buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.button {
  margin: 0 10vw;
}

.images {
  display: flex;
  justify-content: center;
}

.delete {
  position: relative;
  top: 16px;
  right: 15px;
}

.button svg,
.delete svg {
  filter: drop-shadow(3px 3px 3px #999);
  transition: 0.3s;
  transform: rotate(-0.2deg);
}

.button svg:hover,
.delete svg:hover {
  filter: drop-shadow(4px 4px 4px #999);
  transform: rotate(-0.6deg);
  cursor: pointer;
}

.delete svg {
  filter: drop-shadow(1px 1px 1px #999);
}

.delete svg:hover {
  filter: drop-shadow(1.5px 1.5px 1.5px);
}

input[type="file"] {
  visibility: hidden;
  position: absolute;
}

.img__upload {
  max-height: 220px;
  max-width: 220px;
  padding: 10px;
  border: 1px dashed #999;
}

/* Loading */
.loading-wrapper,
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  width: 200px;
  height: 200px;
}

.loading .background {
  border-radius: 50%;
  background: #6762a6;
  border: 3px solid #c9c3e6;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  animation: pulse-colors 2s infinite alternate linear;
}

.loading i {
  margin: 25px 5px 5px 55px;
  float: left;
  font-size: 10em !important;
  color: #fff;
  animation: pulse-icon 4s infinite alternate linear;
}

#notification-wrapper {
  position: absolute;
}

@keyframes pulse-colors {
  from {
    background: #c9c3e6;
    border: 3px solid #a29ccc;
  }
  to {
    background: #6762a6;
    border: 3px solid #c9c3e6;
  }
}

/* Animations */
.fadein {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.rolling {
  position: absolute;
  animation: move 6s ease-out;
  filter: drop-shadow(4px 4px 4px #999);
}

@-webkit-keyframes move {
  0% {
    left: -5%;
  }
  100% {
    left: 105%;
  }
}

@keyframes pulse-icon {
  from {
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  }
  to {
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.55);
  }
}
