.modals-d {
  width: 90%;
  background: #fff;
  box-shadow: 0 2px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 10vh;
  left: 5%;
  overflow-y: auto;
  height: 50vh;
  background: rgb(255, 255, 255);
  z-index: 21;
}

.modal__contents {
  padding: 5rem 1rem;
}

@media only screen and (min-width: 768px) {
  .modals-d {
    width: 30rem;
    left: calc((100% - 30rem) / 2);
  }
}
