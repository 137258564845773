.carousel-item {
  max-height: 80vh;
  height: calc (100% - 20vh);
}

.height-45 {
  max-height: 45vh;
  height: calc (100% - 55vh);
}

.slide-container {
  width: 100%;
  margin: auto;
  max-height: 80vh;
  height: calc (100% - 20vh);
}
.slide-container > :first-child {
  margin-bottom: -20px;
}
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
  max-height: 80vh;
  height: calc (100% - 20vh);
}

.each-fade .image-container img {
  width: 100%;
}
.indicators div {
  display: none;
}
