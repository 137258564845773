#contact {
  background: #191919;
  padding-top: 96px;
  padding-bottom: 102px;
  color: #636363;
  display: flex;
  justify-content: center;
}
#contact .section-head {
  margin-bottom: 42px;
}

#contact a,
#contact a:visited {
  color: #11abb0;
}
#contact a:hover,
#contact a:focus {
  color: #fff;
}

#contact h1 {
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ebeeee;
  margin-bottom: 6px;
}
#contact h1 span {
  display: none;
}
#contact h1:before {
  font-family: "FontAwesome";
  content: "\f0e0";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #ebeeee;
}

#contact h4 {
  font: 16px/24px "opensans-bold", sans-serif;
  color: #ebeeee;
  margin-bottom: 6px;
}
#contact p.lead {
  font: 18px/36px "opensans-light", sans-serif;
  padding-right: 3%;
}
#contact .header-col {
  padding-top: 6px;
}

/* contact form */
#contact form {
  margin-bottom: 30px;
}
#contact label {
  font: 15px/24px "opensans-bold", sans-serif;
  margin: 12px 0;
  color: #ebeeee;
  display: inline-block;
  /* float: left; */
  width: 26%;
}
#contact input,
#contact textarea,
#contact select {
  padding: 18px 20px;
  color: #eee;
  background: #373233;
  margin-bottom: 42px;
  border: 0;
  outline: none;
  font-size: 15px;
  line-height: 24px;
  width: 65%;
}
#contact input:focus,
#contact textarea:focus,
#contact select:focus {
  color: #fff;
  background-color: #11abb0;
}
#contact button.submit {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  background: #0d0d0d;
  border: none;
  cursor: pointer;
  height: auto;
  display: inline-block;
  border-radius: 3px;
  margin-left: 26%;
}
#contact button.submit:hover {
  color: #0d0d0d;
  background: #fff;
}
#contact span.required {
  color: #11abb0;
  font-size: 13px;
}
#message-warning,
#message-success {
  display: none;
  background: #0f0f0f;
  padding: 24px 24px;
  margin-bottom: 36px;
  width: 65%;
  margin-left: 26%;
}
#message-warning {
  color: #d72828;
}
#message-success {
  color: #11abb0;
}

#message-warning i,
#message-success i {
  margin-right: 10px;
}
#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px;
}

/* .columns {
  position: relative;
  padding: 0 20px;
  min-height: 1px;
  float: left;
}
#contact form {
  margin-bottom: 30px;
}

#contact label {
  font: 1.2rem "opensans-bold", sans-serif;
  margin: 12px 0;
  color: #ebeeee;
  display: inline-block;
  float: left;
  width: 26%;
}
#contact {
  background: #191919;
  padding-top: 96px;
  padding-bottom: 102px;
  color: #636363;
}
#contact input,
#contact textarea,
#contact select {
  padding: 18px 20px;
  color: #eee;
  background: #373233;
  margin-bottom: 42px;
  border: 0;
  outline: none;
  font-size: 15px;
  line-height: 24px;
  width: 65%;
}
#message-warning {
  color: #d72828;
}
#message-warning,
#message-success {
  display: none;
  background: #0f0f0f;
  padding: 24px 24px;
  margin-bottom: 36px;
  width: 65%;
  margin-left: 26%;
}

#message-success {
  color: #11abb0;
}

#contact span.required {
  font: 1.2rem "opensans-bold", sans-serif;
  color: #11abb0;
  font-size: 13px;
}
#contact div.submit {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  background: #0d0d0d;
  border: none;
  cursor: pointer;
  height: auto;
  display: inline-block;
  border-radius: 3px;
  margin-left: 26%;
}

#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px;
} */
