.imgPreview {
  text-align: center;
  max-width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
}
.img {
  width: 100%;
  height: 100%;
}

.previewText {
  width: 100%;
  margin-top: 20px;
}
.submitButton {
  padding: 6px 23px;
  background: #3f4257;
  border-radius: 30px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
}

.submitButton:hover {
  background: #545972;
}

.centerText {
  text-align: center;
  width: 500px;
}
